<template>
  <ul class="menu-inner py-1">
    <li class="menu-item" :class="{ active: $route.path === '/home' }">
      <router-link
        :to="user.status == 'Approved' ? '/home' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-dashboard"></i>
        <div data-i18n="Dashboards">Dashboard</div>
      </router-link>
    </li>
    <!--	<li class="menu-item" :class="{ 'active': $route.path === '/container-search' }">
		  <router-link :to="(user.status == 'Approved') ? '/container-search' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-br-search"></i>
			   <div data-i18n="Port">
				Search Container
			   </div>
		  </router-link>
		</li>
		<li class="menu-item" :class="{ 'active': $route.path === '/booking-list-exporter' }">
		  <router-link :to="(user.status == 'Approved') ? '/booking-list-exporter' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Booking Requests
			   </div>
		  </router-link>
		</li>

		<li class="menu-item" :class="{ 'active': $route.path === '/link-request' }">
		  <router-link :to="(user.status == 'Approved') ? '/link-request' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Link Requests
			   </div>
		  </router-link>
		</li>

		<li class="menu-item" :class="{ 'active': $route.path === '/shipping-line-booking-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/shipping-line-booking-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
			 Booking List
			   </div>
		  </router-link>
		</li> -->

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/linking-request-received' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/linking-request-received' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-list"></i>

        <div data-i18n="Port">Linking Request Received</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/delinking-request-received' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/delinking-request-received' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-list"></i>
        <div data-i18n="Port">Delinking Request Received</div>
      </router-link>
    </li>
    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/notification-list' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/notification-list' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-bell-notification-social-media"></i>

        <div data-i18n="Port">Notification</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/shipping-line-history' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/shipping-line-history' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-time-past"></i>
        <div data-i18n="Port">History</div>
      </router-link>
    </li>

    <li
      class="menu-item text-start"
      :class="{ active: $route.path === '/help-and-support' }"
    >
      <router-link
        :to="user.status == 'Approved' ? '/help-and-support' : '#'"
        class="menu-link"
        :class="user.status == 'Approved' ? '' : 'text-light'"
      >
        <i class="menu-icon fi fi-rr-phone-call"></i>

        <div data-i18n="Port">Help & Support</div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
export default {
  name: "OceanCarrierNav",
  data() {
    return {
      user: {},
    };
  },

  methods: {
    profileCompleateORNot() {
      this.isLoading = true;
      axios
        .get(
          `customer/user/${JSON.parse(localStorage.getItem("userData")).id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.user = response.data.data;
        })
        .catch((error) => {
          console.error("Error loading user:", error);
          if (error.response.status == 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("role");
            this.$router.push({ name: "Login" });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.checkConnection()) {
      this.profileCompleateORNot();
    }
  },
};
</script>

<style scoped>
/* .disabled-card{
        pointer-events: none !important;
      
    } */
</style>
